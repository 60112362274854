export const DOWNLOAD_APPLE_PATH = 'https://apps.apple.com/us/app/mods-for-minecraft-craft-mods/id1615405964';
export const DOWNLOAD_GOOGLE_PATH = 'https://play.google.com/store/apps/details?id=com.xiaoruo.craftmods';
export const DOWNLOAD_APK_PATH = '';

export const DEVICE_TYPE_ENUM = { UNKNOWN: 0, APPLE: 1, NOT_APPLE: 2, WINDOW: 3, ANDROID: 4, IOS: 5 };

export const CONTENT_TEXT_EN = {
  GET_APP: 'Download',

  // 第一屏
  FIRST_TITLE_1: 'The Best Addon',
  FIRST_TITLE_2: 'And Skin Creator',
  FIRST_TITLE_3: 'For MCPE',

  FIRST_DESC_1: 'Contain addon, mod, map, skin, seed, and texture',
  FIRST_DESC_2: 'Contain a community for all incredible creations',

  // 第二屏
  SECOND_TITLE: 'Massive Collection',

  SECOND_CONTENT_SKIN: '3000+ Skins',
  SECOND_CONTENT_ADDON: '2000+ Addons',
  SECOND_CONTENT_MAP: '1500+ Maps',

  // 第三屏
  THIRD_TITLE: 'Professional Editor',

  THIRD_CONTENT_ADDON: 'Addon',
  THIRD_CONTENT_ADDON_DESC: [
    'Create new Entities',
    'Modify mobs model, behavior and skins',
    'Change any sound from Minecraft'
  ],

  THIRD_CONTENT_SKIN: 'Skin',
  THIRD_CONTENT_SKIN_DESC: [
    '2D & 3D skin editor',
    'A lot of useful tools for editing: Pencil, Eraser, Bucket, Gradient, Grid, Mirror & Dither'
  ],

  THIRD_CONTENT_ANIMATION: 'Animation',
  THIRD_CONTENT_ANIMATION_DESC: [
    'We have the best clothing for your minecraft skins',
    'Billions of combinations of skins to create'
  ],

  THIRD_CONTENT_DRESS_UP: 'Dress up',
  THIRD_CONTENT_DRESS_UP_DESC: [
    'Modify existing animations of creatures or add fun actions to your creatures, making your mods even more enjoyable'
  ],

  THIRD_CONTENT_WORLD: 'World',
  THIRD_CONTENT_WORLD_DESC: [
    'Browse and experience the new Minecraft worlds',
    'Export and play the worlds with a simple tap'
  ],

  // 第四屏
  FOURTH_TITLE: 'Community',
  FOURTH_TITLE_PC: 'Minecraft Community',

  FOURTH_CONTENT_DESC_1: 'Join our weekly challenges to get inspiration for your creations and win gem rewards',
  FOURTH_CONTENT_DESC_2: 'You can make a home for your communities and friends to stay close and have fun over text, image, and voice chat',
  FOURTH_CONTENT_DESC_3: 'Our Minecraft community never stops amazing us with their creativity. In our community, we gather best work—addons, maps, videos, audios, posts, and more',

  // 第五屏
  FIFTH_TITLE: 'gameplay',
  FIFTH_TITLE_PC: 'gameplay with craft addons',

  // 第六屏
  SIXTH_TITLE: 'USER TALKS',
  SIXTH_TITLE_PC: 'Voice From Craft Addons',

  // 底部
  BOTTOM_TITLE: 'Get ready to discover Craft Addons!',
  BOTTOM_CONTENT_CONTACT_US: 'Contactus',
  BOTTOM_CONTENT_PRIVACY: 'Privacy',
  BOTTOM_CONTENT_TERMS: 'Terms',
  BOTTOM_CONTENT_COPYRIGHT: '@2024 XIAORUO Inc. All Rights Reserved.',
  BOTTOM_COPYRIGHT: '粤ICP备2023083723号-2A'
};

export const CONTENT_TEXT_CN = {
  GET_APP: '免费下载App',

  // 第一屏
  FIRST_TITLE_1: '《我的世界国际版》',
  FIRST_TITLE_2: '最佳插件/模组/皮肤创',
  FIRST_TITLE_3: '建器',
  FIRST_DESC_1: '免费获取海量热门插件、皮肤、地图、纹理和种子',
  FIRST_DESC_2: '加入玩家社区，与朋友、家人和全世界分享您的创作',

  // 第二屏
  SECOND_TITLE: '海量游戏资源库',
  SECOND_CONTENT_SKIN: '3000+套皮肤',
  SECOND_CONTENT_ADDON: '2000+个插件',
  SECOND_CONTENT_MAP: '1500+张地图',

  // 第三屏
  THIRD_TITLE: '超级编辑器',

  THIRD_CONTENT_ADDON: '插件',
  THIRD_CONTENT_ADDON_DESC: [
    '支持创建全新实体和生物部落',
    '支持修改实体模型和行为数据',
    '支持创建和修改游戏中的任何声音'
  ],

  THIRD_CONTENT_SKIN: '皮肤',
  THIRD_CONTENT_SKIN_DESC: [
    '支持2D和3D模式创建新皮肤',
    '提供专业的皮肤编辑工具：各种特效笔刷、橡皮、桶、渐变、镜像、抖动等'
  ],

  THIRD_CONTENT_ANIMATION: '动效',
  THIRD_CONTENT_ANIMATION_DESC: [
    '支持随意修改游戏中生物动效'
  ],

  THIRD_CONTENT_DRESS_UP: '套装',
  THIRD_CONTENT_DRESS_UP_DESC: [
    '一键换装，随意搭配，新手推荐'
  ],

  THIRD_CONTENT_WORLD: '地图',
  THIRD_CONTENT_WORLD_DESC: [
    '一键导出喜爱的地图，与朋友，家人分享'
  ],

  // 第四屏
  FOURTH_TITLE: '社区',
  FOURTH_TITLE_PC: '玩家社区',

  FOURTH_CONTENT_DESC_1: '参加每周的挑战赛，赢取金币大奖',
  FOURTH_CONTENT_DESC_2: '专属聊天室，帮你匹配更多玩家朋友',
  FOURTH_CONTENT_DESC_3: '在这里发布您创建的插件、皮肤和世界，分享您的创意！您可以下载并安装其他人的作品',

  // 第五屏
  FIFTH_TITLE: '更多玩法',
  FIFTH_TITLE_PC: '更多玩法',

  // 第六屏
  SIXTH_TITLE: '用户评价',
  SIXTH_TITLE_PC: '用户评价',

  // 底部
  BOTTOM_TITLE: '免费获取 Craft Addons',
  BOTTOM_CONTENT_CONTACT_US: '联系我们',
  BOTTOM_CONTENT_PRIVACY: '隐私政策',
  BOTTOM_CONTENT_TERMS: '用户协议',
  BOTTOM_CONTENT_COPYRIGHT: '@2024 小若科技 版权所有',
  BOTTOM_COPYRIGHT: '粤ICP备2023083723号-2A'
}
