<template>
  <div class="nav">
    <div class="nav-inner">
      <div class="nav-logo" @click="toTop">
        <img :src="logoIcon">
        <!-- <img :src="appNameIcon"> -->
      </div>
      
      <div class="nav-icon" v-if="0 === LANGUAGE_TYPE">
        <a href="https://www.youtube.com/channel/UCZR7C57g236qE3X-AnCrxWw" target="_blank">
          <img :src="youtubeIcon">
        </a>
        <a href="https://www.tiktok.com/@craftmodsapp" target="_blank">
          <img :src="tiktokIcon">
        </a>
      </div>

      <div class="nav-icon-cn" v-else>
        <a href="https://v.douyin.com/ij6r2eh7/ 0@0.com" target="_blank">
          <img :src="tiktokIcon">
        </a>
        <a href="https://www.xiaohongshu.com/user/profile/5b29b292db2e601930317c8d?xhsshare=CopyLink&appuid=5b29b292db2e601930317c8d&apptime=1717135194" target="_blank">
          <img :src="redbookIcon">
        </a>
        <a href="https://b23.tv/8HbrutT" target="_blank">
          <img :src="bilibiliIcon">
        </a>
        <a href="https://v.kuaishou.com/BVldMY" target="_blank">
          <img :src="kuaiIcon">
        </a>
      </div>

      <div class="nav-get-app" @click="toTop">
        <a>
          <span :class="(0 === LANGUAGE_TYPE ? 'app-baloo2-bold-font' : 'app-sourcehan-medium-font')">
            {{ CONTENT_TEXT.GET_APP }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppNav',
  props: {},

  data() {
    return {
      logoIcon: require('@/assets/craftmods-icon.png'),
      // appNameIcon: require('@/assets/craftmods-name.webp'),
      tiktokIcon: require('@/assets/tiktok-icon.webp'),
      youtubeIcon: require('@/assets/youtube-icon.webp'),
      kuaiIcon: require('@/assets/kuai-icon.webp'),
      redbookIcon: require('@/assets/redbook-icon.webp'),
      bilibiliIcon: require('@/assets/bilibili-icon.webp')
    };
  },

  mounted() {
    const navGetApp = document.querySelector('.nav-get-app');
    if (this.IS_MOBILE) navGetApp.style.display = 'none';
  },

  methods: {
    toTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
</script>

<style scoped>
.nav {
  width: 100%;
  height: 70px;
  background: #252525;

  position: fixed;
  z-index: 999;
  top: 0;
}

.nav-inner {
  height: 100%;
  margin: 0 auto;
  padding: 0 26px;
  min-width: 170px;
  /* margin: 0 15%; */
  max-width: 1270px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-logo {
  margin-right: auto !important;
  cursor: pointer;
}

.nav-logo img:nth-child(1) {
  height: 54px;
  border-radius: 12px;
  border: 2px solid #383838;
}

.nav-logo img:nth-child(2) {
  height: 50px;
}

.nav-icon img, .nav-icon-cn img {
  width: 44px;
  height: 44px;
  margin-left: 36px;
}

.nav-icon-cn {
  min-width: 260px;
}

.nav-get-app {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 199px;
  height: 46px;
  cursor: pointer;
  margin-left: 36px;

  border-radius: 10px;
  box-shadow: inset 0px -5px 0px 0px rgba(0,0,0,0.16);
  background: linear-gradient( 180deg, #70E864 0%, #34A923 100%);
}

.nav-get-app span {
  font-weight: 600;
  font-size: 24px;
  color: #000000;

  text-shadow: 1px 1px 0 #68F857;
}

/* @media (max-width: 1600px) {
  .nav-inner {
    height: 100%;
    margin: 0 auto;
    padding: 0 26px;
    min-width: 170px;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
} */

@media (max-width: 750px) {
  .nav {
    height: 61px;
  }

  .nav-logo img:nth-child(1) {
    height: 40px;
  }

  .nav-logo img:nth-child(2) {
    height: 35px;
  }

  .nav-icon img, .nav-icon-cn img {
    width: 40px;
    height: 40px;
    margin-left: 24px;
  }

  .nav-get-app {
    display: none;
  }
}

@media (max-width: 362px) {
  .nav-logo img:nth-child(2) {
    display: none;
  }
}

@media (max-width: 220px) {
  .nav {
    display: flex;
    justify-content: center;
  }
}
</style>
