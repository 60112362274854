<template>
  <div class="bottom-screen">
    <div class="content-box">
      <div
        :class="
          'content-box-title ' +
          'craftmods-font-title ' + 
          (0 === LANGUAGE_TYPE ? 'app-saf-fix-font' : 'app-douyin-font')">
        <span>{{ CONTENT_TEXT.BOTTOM_TITLE }}</span>
      </div>

      <div class="content-box-icons" v-if="0 === LANGUAGE_TYPE">
        <a :href="applePath" target="_blank" @click="toApple"><img :src="appleIcon"></a>
        <a :href="googlePath" target="_blank" @click="toGoole"><img :src="googleIcon"></a>
      </div>

      <!-- content-box-icons-cn -->
      <div class="content-box-icons" v-else>
        <a :href="applePath" target="_blank" @click="toApple"><img :src="appleCnIcon"></a>
        <a :href="googlePath" target="_blank" @click="toGoole"><img :src="googleCnIcon"></a>
        <!-- <a :href="apkPath" target="_blank"><img :src="apkIcon"></a> -->
      </div>

      <div
        :class="
          'content-box-footer ' +
          (0 === LANGUAGE_TYPE ? 'app-baloo2-semibold-font' : 'app-sourcehan-bold-font')">
        <div>
          <a href="mailto:craftmods@icloud.com">{{ CONTENT_TEXT.BOTTOM_CONTENT_CONTACT_US }}</a>
        </div>
        <div>
          <a href="https://craftmods.me/privacy" target="_blank">
            {{ CONTENT_TEXT.BOTTOM_CONTENT_PRIVACY }}
          </a> & 
          <a href="https://craftmods.me/terms" target="_blank">
            {{ CONTENT_TEXT.BOTTOM_CONTENT_TERMS }}
          </a>
        </div>
        <div><span>{{ CONTENT_TEXT.BOTTOM_CONTENT_COPYRIGHT }}</span></div>
      </div>

      <div :class="
          'content-box-footer ' +
          (0 === LANGUAGE_TYPE ? 'app-baloo2-semibold-font' : 'app-sourcehan-bold-font')">
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
            {{ CONTENT_TEXT.BOTTOM_COPYRIGHT }} 
          </a>

        </div>

    </div>
    
  </div>
</template>

<script>
import { DOWNLOAD_APPLE_PATH, DOWNLOAD_GOOGLE_PATH, DOWNLOAD_APK_PATH } from '@/constants/index';

import { statisticsReq } from '@/utils';

export default {
  name: 'BottomScreen',

  data() {
    return {
      googleIcon: require('@/assets/download-google-icon.webp'),
      appleIcon: require('@/assets/download-apple-icon.webp'),

      googleCnIcon: require('@/assets/download-google-cn-icon.webp'),
      appleCnIcon: require('@/assets/download-apple-cn-icon.webp'),
      // apkIcon: require('@/assets/download-apk-cn-icon.webp'),

      applePath: DOWNLOAD_APPLE_PATH,
      googlePath: DOWNLOAD_GOOGLE_PATH,
      apkPath: DOWNLOAD_APK_PATH
    };
  },

  methods: {
    toApple() {
      statisticsReq(5);
    },

    toGoole() {
      statisticsReq(6);
    }
  }
}
</script>

<style scoped>
.bottom-screen {
  display: flex;
  justify-content: center;
  
  width: 100%;
  padding: 43px 0 165px 0;
}

.content-box {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 0 20px;
  max-width: 1100px;
}

.content-box-title {
  display: block;
  text-align: center;
  min-width: 350px;
}

.content-box-icons, .content-box-icons-cn {
  margin: 80px 0 100px 0;
}

.content-box-icons img {
  height: 80px;
  margin: 0 23px;
}
.content-box-icons-cn img {
  height: 76px;
  margin: 0 21px;
}

.content-box-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 100%;
  font-size: 30px;
  color: #666666;
}

.content-box-footer div {
  margin: 0 15px;
}

.content-box-footer a {
  color: #666666;
}

.copyright-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 100%;
  font-size: 30px;
  /* color: #666666; */
}

@media (max-width: 860px) {
  .content-box-icons-cn {
    display: none;
  }
}

@media (max-width: 750px) {
  .content-box-icons {
    display: none;
  }

  .content-box-title {
    display: none;
  }

  .content-box-footer {
    align-items: center;
    flex-direction: column;
    justify-content: unset;

    font-size: 20px;
    text-align: center;
  }

  .content-box-footer div {
    margin: 7px 0;
  }

  .bottom-screen {
    display: flex;
    justify-content: center;
    
    width: 100%;
    padding: 43px 0 110px 0;
  }
}
</style>
