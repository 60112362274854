<template>
  <div class="fourth-screen" id="fourthScreen">
    <div class="content-box">
      <div
        :class="
          'content-box-title ' +
          'craftmods-font-title ' +
          (0 === LANGUAGE_TYPE ? 'app-saf-fix-font' : 'app-douyin-font')">
        <span>{{ CONTENT_TEXT.FOURTH_TITLE_PC }}</span>
      </div>

      <div
        :class="
          'content-box-title-phone ' +
          'craftmods-font-title ' +
          (0 === LANGUAGE_TYPE ? 'app-saf-fix-font' : 'app-douyin-font')">
        <span>{{ CONTENT_TEXT.FOURTH_TITLE }}</span>
      </div>

      <div class="content-box-desc">
        <span
          :class="(0 === LANGUAGE_TYPE ? 'app-baloo2-medium-font' : 'app-sourcehan-medium-font')">
          {{ communityList[activeIndex].desc }}
        </span>
      </div>

      <div class="content-box-community">
        <swiper :options="swiperOption" ref="communitySwiper" @slideChange="onSlideChange">
          <swiper-slide
            v-for="(item, index) of communityList"
            :key="index"
            :data-swiper-autoplay="item.duration * 1000 + 500">
            <img :id="index" :src="item.preloadPic" class="display-video">
            <!-- <video
              class="display-video"
              :id="index"
              :src="item.video"
              :poster="item.preloadPic"
              loop
              muted
              playsinline
              preload="auto">
            </video> -->
          </swiper-slide>
        </swiper>
      </div>

      <div class="content-box-community-phone">
        <swiper :options="swiperOptionPhone" ref="communitySwiperPhone" @slideChange="onSlideChangePhone">
          <swiper-slide
            v-for="(item, index) of communityList"
            :key="index"
            :data-swiper-autoplay="item.duration * 1000 + 500">
            <img :id="index" :src="item.preloadPic" class="display-video-phone">
            <!-- <video
              class="display-video-phone"
              :id="index"
              :src="item.video"
              :poster="item.preloadPic"
              loop
              muted
              playsinline>
            </video> -->
          </swiper-slide>
        </swiper>

        <div class="community-swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWindowWidth } from '@/utils';

export default {
  name: 'FourthScreen',
  props: {},

  mounted() {
    // 监听当前屏是否出现在视口
    const clientWidth = getWindowWidth();
    // let videos = document.querySelectorAll('.display-video');
    // if (clientWidth <= 750) videos = document.querySelectorAll('.display-video-phone');

    const io = new IntersectionObserver(async (entries) => {
      const item = entries[0];

      if (item && item.isIntersecting) {
        this.$nextTick(() => {
          clientWidth <= 750 ? this.onSlideChangePhone() : this.onSlideChange();
        });
      } else {
        // for (const video of videos) {
        //   video.currentTime = 0; // 设置当前帧
        //   // await video.pause(); // 停止播放
        // }
      }
    });
    io.observe(document.getElementById('fourthScreen'));
  },

  data() {
    return {
      communityList: [
        {
          video: 'https://addonscraft104827-dev.s3.us-west-1.amazonaws.com/public/collection/web/mc-app-challenge.mp4',
          desc: this.CONTENT_TEXT.FOURTH_CONTENT_DESC_1,
          duration: 12,
          preloadPic: require('@/assets/cover/app-challenge.webp')
        },
        {
          video: 'https://addonscraft104827-dev.s3.us-west-1.amazonaws.com/public/collection/web/mc-app-chat.mp4',
          desc: this.CONTENT_TEXT.FOURTH_CONTENT_DESC_2,
          duration: 25,
          preloadPic: require('@/assets/cover/app-chat.webp')
        },
        {
          video: 'https://addonscraft104827-dev.s3.us-west-1.amazonaws.com/public/collection/web/mc-app-community.mp4',
          desc: this.CONTENT_TEXT.FOURTH_CONTENT_DESC_3,
          duration: 23,
          preloadPic: require('@/assets/cover/app-community.webp')
        }
      ],
      
      swiperOption: {
        centeredSlides: true,
        spaceBetween: 20,
        slidesPerView: 3,
        loop: true,
        autoplay: {
          delay: 7000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      },

      swiperOptionPhone: {
        grabCursor: true,
        effect: 'creative',
        loop: true,
        autoplay: {
          delay: 15000,
          disableOnInteraction: false
        },

        pagination: {
          el: '.community-swiper-pagination',
          clickable: true
        }
      },

      activeIndex: 0
    };
  },

  methods: {
    async onSlideChange() {
      const videos = document.querySelectorAll('.display-video');

      for (let i = 0; i < videos.length; i++) {
        const video = videos[i];

        if (i === this.$refs.communitySwiper.$swiper.activeIndex) {
          this.activeIndex = Number(video.id);
        }

        // video.currentTime = 0; // 设置当前帧
        // if (i === this.$refs.communitySwiper.$swiper.activeIndex) {
        //   this.activeIndex = Number(video.id);
        //   await video.play(); // 开始播放
        //   video.style.border = '4px solid #70e864';
        // } else {
        //   await video.pause(); // 停止播放
        //   video.style.border = 'unset'
        // }
      }
    },

    async onSlideChangePhone() {
      const videos = document.querySelectorAll('.display-video-phone');

      for (let i = 0; i < videos.length; i++) {
        const video = videos[i];

        if (i === this.$refs.communitySwiperPhone.$swiper.activeIndex) {
          this.activeIndex = Number(video.id);
        }

        // video.currentTime = 0; // 设置当前帧
        // if (i === this.$refs.communitySwiperPhone.$swiper.activeIndex) {
        //   this.activeIndex = Number(video.id);
        //   await video.play(); // 开始播放
        // } else {
        //   await video.pause(); // 停止播放
        // }
      }
    }
  }
}
</script>

<style scoped>
.fourth-screen {
  display: flex;
  justify-content: center;
  
  width: 100%;
  padding: 85px 0 97px 0;
}

.content-box {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  min-width: 280px;
}

.content-box-title {
  display: block;
}

.content-box-title-phone {
  display: none;
}

.content-box-desc {
  font-size: calc(0.45rem + 1.5vw) !important;
  color: #FCFCFC;

  margin: 60px 0;
  text-align: center;
  max-width: 50%;
}

.content-box-community {
  width: 100%;
  display: block;
  max-width: 1700px;
}

.content-box-community-phone {
  display: none;
  width: 100%;
}

.display-video, .display-video-phone {
  width: 110%;
  max-width: 618px;
  border-radius: 20px;
}

.display-video-phone {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.8);

  opacity: 0.5;
  border-radius: 20px;
}

.swiper-slide-active, .swiper-slide-duplicate-active {
  transform: scale(1);
  opacity: 1;
}

.community-swiper-pagination {
  position: relative;
  bottom: -40px;
}

.community-swiper-pagination >>> .swiper-pagination-bullet {
  width: 37px;
  margin: 0 8px;
  border-radius: unset;

  background-color: unset;
  background-image: url('@/assets/selected-phone.webp');
}

@media (max-width: 750px) {
  .fourth-screen {
    padding: 40px 0 80px 0;
  }

  .content-box-community {
    display: none;
  }

  .content-box-community-phone {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .content-box-title {
    display: none;
  }

  .content-box-title-phone {
    display: block;
  }

  .content-box-desc {
    font-size: calc(0.9rem + 1.5vw) !important;
    max-width: 90%;
    margin: 32px 0 40px 0;
  }

  .display-video-phone {
    width: 90%;
    border-radius: 14px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    margin: 0;
    transform: unset;
  }

  .swiper-slide-active, .swiper-slide-duplicate-active {
    transform: unset;
  }
}
</style>
