<template>
  <div class="second-screen" id="secondScreen">
    <div class="content-box">
      <img :src="libraryBg" class="library-bg">
      <img :src="libraryBgPhone" class="library-bg-phone">

      <div class="content-box-title">
        <span
          :class="
            'craftmods-font-title ' +
            (0 === LANGUAGE_TYPE ? 'app-saf-fix-font' : 'app-douyin-font')">
          {{ CONTENT_TEXT.SECOND_TITLE }}
        </span>
      </div>

      <div class="content-box-library">
        <swiper :options="swiperOption">
          <swiper-slide
            v-for="(item, index) of libraryContentList"
            :key="index">
            <div>
              <div class="library-head">
                <span
                  :class="(0 === LANGUAGE_TYPE ? 'app-baloo2-bold-font' : 'app-sourcehan-bold-font')">
                  {{ item.text }}
                </span>
                <img :src="libraryHeadBg">
              </div>
              <div style="border-radius: 0 0 15px 15px;overflow: hidden;">
                <video
                  class="library-video"
                  :src="item.video"
                  :poster="item.preloadPic"
                  autoplay
                  loop
                  muted
                  playsinline>
                </video>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="content-box-library-phone">
        <swiper :options="swiperOptionPhone" @slideChange="onSlideChange" ref="librarySwiper">
          <swiper-slide
            v-for="(item, index) of libraryContentList"
            :key="index"
            data-swiper-autoplay="15500">
            <div>
              <div class="library-head">
                <span :class="(0 === LANGUAGE_TYPE ? 'app-baloo2-bold-font' : 'app-sourcehan-bold-font')">
                  {{ item.text }}
                </span>
                <img :src="libraryHeadBg">
              </div>
              <div style="border-radius: 0 0 15px 15px;overflow: hidden;">
                <video
                  class="library-video-phone"
                  :src="item.video"
                  :poster="item.preloadPic"
                  muted
                  playsinline>
                </video>
              </div>
            </div>
          </swiper-slide>
        </swiper>

        <div class="library-swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondScreen',
  props: {},

  data() {
    return {
      libraryBg: require('@/assets/library-bg.webp'),
      libraryBgPhone: require('@/assets/library-bg-phone.webp'),
      libraryHeadBg: require('@/assets/library-head-bg.webp'),
      selectIcon: require('@/assets/select-phone.webp'),
      selectedIcon: require('@/assets/selected-phone.webp'),

      libraryContentList: [
        {
          text: this.CONTENT_TEXT.SECOND_CONTENT_SKIN,
          video: 'https://addonscraft104827-dev.s3.us-west-1.amazonaws.com/public/collection/web/mc-library-skin.mp4',
          preloadPic: require('@/assets/cover/library-skin.webp')
        },
        {
          text: this.CONTENT_TEXT.SECOND_CONTENT_ADDON,
          video: 'https://addonscraft104827-dev.s3.us-west-1.amazonaws.com/public/collection/web/mc-library-addon.mp4',
          preloadPic: require('@/assets/cover/library-addon.webp')
        },
        {
          text: this.CONTENT_TEXT.SECOND_CONTENT_MAP,
          video: 'https://addonscraft104827-dev.s3.us-west-1.amazonaws.com/public/collection/web/mc-library-map.mp4',
          preloadPic: require('@/assets/cover/library-map.webp')
        }
      ],

      swiperOption: {
        slidesPerView: 'auto',
        freeMode: true
      },

      swiperOptionPhone: {
        effect: 'creative',
        loop: true,

        pagination: {
          el: '.library-swiper-pagination',
          clickable: true
        }
      }
    };
  },

  mounted() {
    const io = new IntersectionObserver(async (entries) => {
      const item = entries[0];

      if (item && item.isIntersecting) {
        this.$nextTick(() => this.onSlideChange());
      } else {
        // for (const video of document.querySelectorAll('.display-video-phone')) {
        //   video.currentTime = 0; // 设置当前帧
        //   await video.pause(); // 停止播放
        // }
      }
    });
    io.observe(document.getElementById('secondScreen'));
  },

  destroyed() {
  },

  methods: {
    async onSlideChange() {
      const videos = document.querySelectorAll('.library-video-phone');

      for (let i = 0; i < videos.length; i++) {
        const video = videos[i];

        video.currentTime = 1; // 设置当前帧
        if (i === this.$refs.librarySwiper.$swiper.activeIndex) {
          await video.play(); // 开始播放
        } else {
          // await video.pause(); // 停止播放
        }
      }
    }
  }
}
</script>

<style scoped>
.second-screen {
  display: flex;
  justify-content: center;
  
  width: 100%;
  padding: 52px 0 77px 0;
}

.content-box {
  position: relative;

  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 1400px;
}

.library-bg, .library-bg-phone {
  width: 100%;
}

.library-bg-phone {
  display: none;
}

.content-box-title {
  position: absolute;
  top: 20px;
}

.content-box-library, .content-box-library-phone {
  position: absolute;
  top: 36%;

  display: flex;
  justify-content: space-around;

  width: 100%;
}

.content-box-library-phone {
  display: none;
}

.content-box-library-phone > div {
  display: flex;
  justify-content: space-evenly;
}

.swiper-container >>> .swiper-wrapper {
  justify-content: center !important;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0 2vw;

  width: 25%;
}

.swiper-slide-active, .swiper-slide-duplicate-active {
  transform: scale(1);
}

.library-swiper-pagination {
  position: relative;
  bottom: -40px;
}

.library-swiper-pagination >>> .swiper-pagination-bullet {
  width: 37px;
  margin: 0 8px;
  border-radius: unset;

  background-color: unset;
  background-image: url('@/assets/selected-phone.webp');
}

.library-head {
  display: flex;
  justify-content: center;
  align-items: center;
}

.library-head span {
  position: absolute;

  font-size: calc(0.7rem + 1.5vw) !important;
  color: #FFFFFF;
  font-weight: bold;
}

.library-head img {
  width: 100%;
  border-radius: 15px 15px 0 0;
}

.library-video, .library-video-phone {
  width: 100%;
  height: 100%;

  border-radius: 0 0 15px 15px;
}

@media (max-width: 1700px) {
  .content-box {
    width: 80%;
  }
}

@media (max-width: 1000px) {
  .content-box {
    width: 90%;
  }
}

@media (max-width: 750px) {
  .second-screen {
    padding: 0;
  }

  .content-box {
    width: 100%;
    min-width: unset;
    padding-top: 95px;
    min-width: 330px;
  }

  .content-box-title {
    top: 35px;
  }

  .library-bg {
    display: none;
  }

  .library-bg-phone {
    display: block;
  }

  .swiper-slide {
    width: unset;
  }

  .content-box-library {
    display: none;
  }

  .content-box-library-phone {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    width: 100%;
  }

  .library-head span {
    font-size: calc(1.82rem + 1.5vw) !important;
  }

  .swiper-slide {
    display: flex;
    transition: 300ms;
  
    background-position: center;
    background-size: cover;
    margin: 0;
  }

  .swiper-slide > div {
    width: 80%;
  }

  .swiper-container >>> .swiper-wrapper {
    justify-content: unset !important;
  }
}
</style>
