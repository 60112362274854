<template>
  <div class="fifth-screen">
    <div class="content-box">
      <div
        :class="
          'content-box-title ' +
          'craftmods-font-title ' + 
          (0 === LANGUAGE_TYPE ? 'app-saf-fix-font' : 'app-douyin-font')">
        <span>{{ CONTENT_TEXT.FIFTH_TITLE_PC }}</span>
      </div>

      <div
        :class="
          'content-box-title-phone ' +
          'craftmods-font-title ' +
          (0 === LANGUAGE_TYPE ? 'app-saf-fix-font' : 'app-douyin-font')">
        <span>{{ CONTENT_TEXT.FIFTH_TITLE }}</span>
      </div>

      <div class="content-box-gameplay">
        <swiper :options="swiperOption" ref="gameplaySwiper">
          <swiper-slide
            v-for="(item, index) of (0 === LANGUAGE_TYPE ? gameplayIcons : gameplayCnIcons)"
            :key="index">
            <a :href="item.url" target="_blank" class="gameplay-img">
              <img :src="item.img">
              <img :src="0 === LANGUAGE_TYPE ? ideasIcon : ideasCnIcon">
            </a>
          </swiper-slide>
        </swiper>

        <div class="swiper-button-prev"><img :src="iconArrow"></div>
        <div class="swiper-button-next"><img :src="iconArrow"></div>
      </div>

      <div class="content-box-gameplay-phone">
        <!-- swiper@8.3.2 版本 -->
        <div class="swiper">
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) of (0 === LANGUAGE_TYPE ? gameplayIcons : gameplayCnIcons)"
              :key="index"
              class="swiper-slide">
              <a :href="item.url" target="_blank" class="gameplay-img-phone">
                <img :src="item.img">
                <img :src="0 === LANGUAGE_TYPE ? ideasIcon : ideasCnIcon">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FifthScreen',

  data() {
    return {
      iconArrow: require('@/assets/icon-arrow.svg'),
      ideasIcon: require('@/assets/youtube-ideas.webp'),
      ideasCnIcon: require('@/assets/play-ideas.webp'),
      gameplayIcons: [
        {
          img: require('@/assets/gameplay-1.webp'),
          url: 'https://youtube.com/shorts/H7FJtogNo2E?si=xChba5vlFlOzXVtP'
        },
        {
          img: require('@/assets/gameplay-2.webp'),
          url: 'https://youtube.com/shorts/v5gWbzkE7Oo?si=KP_1Rk7HJpHf0bjh'
        },
        {
          img: require('@/assets/gameplay-3.webp'),
          url: 'https://youtube.com/shorts/N5-FRznxTLw?si=8Cc81_MBss6klqxf'
        },
        {
          img: require('@/assets/gameplay-4.webp'),
          url: 'https://youtube.com/shorts/eX01N10NhCY?si=Q3IJhh1MtXAf0DBI'
        },
        {
          img: require('@/assets/gameplay-5.webp'),
          url: 'https://youtube.com/shorts/k6PnzJ1M1PE?si=VCpqABFQXyc2ZtfU'
        },
        {
          img: require('@/assets/gameplay-6.webp'),
          url: 'https://youtube.com/shorts/tQTLILjnCXA?si=SwOWBTB9cVhoDiyP'
        },
        {
          img: require('@/assets/gameplay-7.webp'),
          url: 'https://youtube.com/shorts/IgOSCQ5zHCo?si=qbCcRWFDBcSj6vdC'
        }
      ],

      gameplayCnIcons: [
        {
          img: require('@/assets/gameplay-1-cn.webp'),
          url: 'https://v.douyin.com/ij6hMxaS'
        },
        {
          img: require('@/assets/gameplay-2-cn.webp'),
          url: 'https://v.douyin.com/ij6hLe4H'
        },
        {
          img: require('@/assets/gameplay-3-cn.webp'),
          url: 'https://v.douyin.com/ij6hQqDo'
        },
        {
          img: require('@/assets/gameplay-4-cn.webp'),
          url: 'https://v.douyin.com/ij6hsbsH'
        },
        {
          img: require('@/assets/gameplay-5-cn.webp'),
          url: 'https://v.douyin.com/ij6k9WhF'
        },
        {
          img: require('@/assets/gameplay-6-cn.webp'),
          url: ' https://v.douyin.com/ij6BDq5j'
        }
      ],

      swiperOption: {
        spaceBetween: 10,
        slidesPerView: 4,

        loop: true,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
      }
    };
  },

  mounted() {
    // eslint-disable-next-line no-undef, no-unused-vars
    var mySwiper = new Swiper ('.swiper', {
      effect: 'cards',
      grabCursor: true,
      loop: true, // 循环模式选项
    });
  },

  methods: {}
}
</script>

<style scoped>
.fifth-screen {
  display: flex;
  justify-content: center;
  
  width: 100%;
  max-width: 1400px;
  padding: 85px 0 90px 0;
}

.content-box {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: 0 20px;
}

.content-box-title {
  display: block;
}

.content-box-title-phone {
  display: none;
}

.content-box-gameplay {
  display: block;

  position: relative;

  width: 80%;
  margin: 85px 0 0 0;
}

.content-box-gameplay-phone {
  display: none;

  width: 78%;
  margin: 40px 0 40px 0;
}

.gameplay-img, .gameplay-img-phone {
  display: flex;
}

.gameplay-img-ideas {
  width: 54px;
}

.gameplay-img {
  position: relative;

  width: 98%;
}

.gameplay-img img:nth-child(1) {
  width: 100%;
  border-radius: 20px;
}
.gameplay-img img:nth-child(2) {
  position: absolute;
  top: 45%;
  left: 40%;

  height: 38px;
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide-active, .swiper-slide-duplicate-active {
  transform: scale(1);
}

.swiper-button-next {
  left: auto;
  right: -72px;
  transform: rotate(180deg);
}

.swiper-button-prev {
  left: -72px;
  right: auto;
}

.swiper-button-next::after, .swiper-button-prev::after {
  content: unset;
}

@media (max-width: 750px) {
  .fifth-screen {
    display: flex;
    justify-content: center;
    
    width: 100%;
    padding: 40px 0 20px 0;
  }

  .content-box-title {
    display: none;
  }

  .content-box-title-phone {
    display: block;
  }
  
  .content-box-gameplay {
    display: none;
  }

  .content-box-gameplay-phone {
    display: block;
  }

  .gameplay-img-phone {
    position: relative;
  
    width: 78%;
  }

  .gameplay-img-phone img:nth-child(1) {
    width: 100%;
    border-radius: 20px;
  }
  .gameplay-img-phone img:nth-child(2) {
    position: absolute;
    top: 45%;
    left: 40%;

    height: 38px;
  }

  .swiper-3d >>> .swiper-slide-shadow {
    background: unset;
  }
}
</style>
