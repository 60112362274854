import { DEVICE_TYPE_ENUM } from "@/constants";

const device = require("current-device").default;

export const getWindowHeight = () => {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
}

export const getWindowWidth = () => {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

export const deviceType = () => {
  if (device.windows() || device.windowsPhone() || device.windowsTablet()) {
    return DEVICE_TYPE_ENUM.WINDOW;
  }

  if (device.android() || device.androidPhone() || device.androidTablet()) {
    return DEVICE_TYPE_ENUM.ANDROID;
  }

  if (device.ios() || device.ipad() || device.ipod() || device.iphone() || device.macos()) {
    return DEVICE_TYPE_ENUM.IOS;
  }

  return DEVICE_TYPE_ENUM.UNKNOWN;
}

export const statisticsReq = (statisticsType = 0) => {
  if (window.location.href.includes('localhost')) return;

  //1.创建对象
  const xhr = new XMLHttpRequest();

  //2.初始化，设置请求方法和url
  let url = 'https://xiaoruo.net:8087/cgi/ca_web_statistics';
  url += '?statistics_type=' + statisticsType;
  url += '&device_type=' + deviceType();
  xhr.open('GET', url);

  //3.发送
  xhr.send();
}

export const isMobile = () => {
  if (
    device.mobile() ||
    device.ipad() ||
    device.ipod() ||
    device.iphone() ||
    device.android() ||
    device.androidPhone()
  ) {
    return true;
  }

  return false;
};

/**
 * 全局的多语言配置
 * 0-en 1-cn
 * @returns {Number}
 */
export const getLanguageTypeByHerf = () => {
  const herf = window.location.href;

  if (herf.includes('craftmods.cn')) return 1;

  if (herf.includes('craftmods.me')) return 0;

  if (herf.includes('localhost')) return 1;

  return 0;
}
