<template>
  <div class="third-screen">
    <div class="content-box">
      <div class="content-box-desc">
        <div
          :class="
            'craftmods-font-title ' +
            (0 === LANGUAGE_TYPE ? 'app-saf-fix-font' : 'app-douyin-font')">
          {{ CONTENT_TEXT.THIRD_TITLE }}
        </div>

        <div
          :class="
            'editor-desc ' +
            (0 === LANGUAGE_TYPE ? 'app-baloo2-medium-font' : 'app-sourcehan-medium-font')">
          <li v-for="(item, index) of editorList[activeIndex].desc" :key="index">
            {{ item }}
          </li>
        </div>

        <div class="editor-select-list">
          <div
            v-for="(item, index) of editorList"
            :key="index"
            :class="index === activeIndex ? 'active' : ''"
            @click="onClickText(index)">
            <span :class="(0 === LANGUAGE_TYPE ? 'app-baloo2-bold-font' : 'app-sourcehan-medium-font')">
              {{ item.text }}
            </span>
          </div>
        </div>
      </div>

      <div class="content-box-editor">
        <swiper :options="swiperOption" @slideChange="onSlideChange" ref="editorSwiper">
          <swiper-slide
            v-for="(item, index) of editorList"
            :key="index"
            :data-swiper-autoplay="item.duration * 1000 + 500">
            <div style="border-radius: 15px;overflow: hidden;border: 4px solid #59d04c;">
              <video
                class="editor-video"
                :id="index"
                :src="item.video"
                :poster="item.preloadPic"
                muted
                playsinline>
              </video>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="content-box-editor-phone">
        <swiper :options="swiperOptionPhone" @slideChange="onSlideChangePhone" ref="editorSwiperPhone">
          <swiper-slide
            v-for="(item, index) of editorList"
            :key="index"
            :data-swiper-autoplay="item.duration * 1000 + 500">
            <div style="border-radius: 20px;overflow: hidden;border: 4px solid #59d04c;">
              <video
                class="editor-video-phone"
                :id="index"
                :src="item.video"
                :poster="item.preloadPic"
                muted
                playsinline>
              </video>
            </div>
          </swiper-slide>
        </swiper>

        <div class="editor-swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWindowWidth } from '@/utils';
export default {
  name: 'ThirdScreen',
  props: {},

  data() {
    return {
      selectIcon: require('@/assets/select-phone.webp'),
      selectedIcon: require('@/assets/selected-phone.webp'),

      editorList: [
        {
          text: this.CONTENT_TEXT.THIRD_CONTENT_ADDON,
          video: 'https://addonscraft104827-dev.s3.us-west-1.amazonaws.com/public/collection/web/mc-editor-addon.mp4',
          duration: 15,
          desc: this.CONTENT_TEXT.THIRD_CONTENT_ADDON_DESC,
          preloadPic: require('@/assets/cover/editor-addon.webp')
        },
        {
          text: this.CONTENT_TEXT.THIRD_CONTENT_SKIN,
          video: 'https://addonscraft104827-dev.s3.us-west-1.amazonaws.com/public/collection/web/mc-editor-skin.mp4',
          duration: 23,
          desc: this.CONTENT_TEXT.THIRD_CONTENT_SKIN_DESC,
          preloadPic: require('@/assets/cover/editor-skin.webp')
        },
        {
          text: this.CONTENT_TEXT.THIRD_CONTENT_ANIMATION,
          video: 'https://addonscraft104827-dev.s3.us-west-1.amazonaws.com/public/collection/web/mc-editor-animation.mp4',
          duration: 17,
          desc: this.CONTENT_TEXT.THIRD_CONTENT_ANIMATION_DESC,
          preloadPic: require('@/assets/cover/editor-animation.webp')
        },
        {
          text: this.CONTENT_TEXT.THIRD_CONTENT_DRESS_UP,
          video: 'https://addonscraft104827-dev.s3.us-west-1.amazonaws.com/public/collection/web/mc-editor-dressup.mp4',
          duration: 26,
          desc: this.CONTENT_TEXT.THIRD_CONTENT_DRESS_UP_DESC,
          preloadPic: require('@/assets/cover/editor-dressup.webp')
        },
        {
          text: this.CONTENT_TEXT.THIRD_CONTENT_WORLD,
          video: 'https://addonscraft104827-dev.s3.us-west-1.amazonaws.com/public/collection/web/mc-editor-world.mp4',
          duration: 13,
          desc: this.CONTENT_TEXT.THIRD_CONTENT_WORLD_DESC,
          preloadPic: require('@/assets/cover/editor-world.webp')
        }
      ],

      swiperOption: {
        effect: 'fade',
        loop: true
      },

      swiperOptionPhone: {
        effect: 'creative',
        loop: true,
        spaceBetween: 20,
        autoplay: {
          delay: 15000,
          disableOnInteraction: false
        },

        pagination: {
          el: '.editor-swiper-pagination',
          clickable: true
        }
      },

      activeIndex: 0
    };
  },

  mounted() {
    // 监听当前屏是否出现在视口
    const clientWidth = getWindowWidth();
    let videos = document.querySelectorAll('.editor-video');
    if (clientWidth <= 910) videos = document.querySelectorAll('.editor-video-phone');

    const io = new IntersectionObserver(async (entries) => {
      const item = entries[0];

      if (item && item.isIntersecting) {
        this.$nextTick(() => {
          clientWidth <= 910 ? this.onSlideChangePhone() : this.onSlideChange();
        });
      } else {
        for (const video of videos) {
          video.currentTime = 0; // 设置当前帧
          // await video.pause(); // 停止播放
        }
      }
    });
    io.observe(document.getElementById('fourthScreen'));
  },

  methods: {
    onClickText(index) {
      this.$refs.editorSwiper.$swiper.activeIndex = index + 1;
      this.onSlideChange();
    },

    async onSlideChange() {
      const videos = document.querySelectorAll('.editor-video');
      const index = this.$refs.editorSwiper.$swiper.activeIndex;

      for (let i = 0; i < videos.length; i++) {
        const video = videos[i];

        video.currentTime = 0; // 设置当前帧
        if (i === index) {
          this.activeIndex = Number(video.id);
          await video.play(); // 开始播放
        } else {
          // await video.pause(); // 停止播放
        }
      }
    },

    async onSlideChangePhone() {
      const videos = document.querySelectorAll('.editor-video-phone');
      const index = this.$refs.editorSwiperPhone.$swiper.activeIndex;

      for (let i = 0; i < videos.length; i++) {
        const video = videos[i];

        video.currentTime = 0; // 设置当前帧
        if (i === index) {
          this.activeIndex = Number(video.id);
          await video.play(); // 开始播放
        } else {
          // await video.pause(); // 停止播放
        }
      }
    }
  }
}
</script>

<style scoped>
.third-screen {
  display: flex;
  justify-content: center;
  
  width: 100%;
  padding: 95px 0;
}

.content-box {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 98%;
  margin: 0 15%;
  min-width: 350px;
}

.editor-desc {
  font-size: calc(0.45rem + 1.5vw) !important;
  color: #FCFCFC;
  max-width: 620px;

  margin: 60px 0 75px 0;
}

.editor-desc > li {
  /* padding-left: 1.4em; */
  text-indent: -1.4em;
}

.editor-select-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  width: 100%;

  max-width: 70%;
}

.editor-select-list div {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36.5%;
  cursor: pointer;
  color: #6C6C6C;
  margin: 0 42px 35px 0;

  border-radius: 14px;
  background: #D9D9D9;
  box-shadow: inset 0px -5px 0px 0px rgba(0,0,0,0.16);
}

.editor-select-list div:hover {
  color: #000000;
  background: linear-gradient( 180deg, #70E864 0%, #34A923 100%), #34875F;
}

.editor-select-list div.active {
  color: #000000;
  background: linear-gradient( 180deg, #70E864 0%, #34A923 100%), #34875F;
}

.editor-select-list span {
  font-size: calc(0.45rem + 1.5vw) !important;
  padding: 10px 0;
}

.content-box-editor {
  position: relative;
  width: 34%;
  max-width: 380px;
  /* margin-top: 65px; */

  display: block;
}

.content-box-editor-phone {
  display: none;

  position: relative;

  width: 100%;
}

.editor-video, .editor-video-phone {
  width: 100%;
  height: 100%;
  margin-bottom: -5px;
  /* border-radius: 15px; */
}

.editor-select-icons {
  display: none;
}

.swiper-container {
  height: auto;
  /* border-radius: 20px; */
  /* border: 4px solid #59d04c; */
}

.swiper-slide {
  display: flex;
  justify-content: center;

  margin: 0;
  
  transition: 300ms;
}

.editor-swiper-pagination {
  display: none;
}

@media (max-width: 940px) {
  .third-screen {
    padding: 40px 0 80px 0;
  }

  .content-box {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .content-box-desc {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .editor-desc {
    font-size: calc(0.8rem + 1.5vw) !important;
    margin: 37px 10px 40px 10px;
  }

  .editor-select-list {
    display: none;
  }

  .content-box-editor {
    display: none;
  }

  .content-box-editor-phone {
    display: block;
  }

  .editor-select-icons {
    display: flex;
    justify-content: space-evenly;

    width: 80%;

    margin: 40px 0 40px 0;
  }

  .editor-swiper-pagination {
    position: relative;
    bottom: -40px;

    display: flex;
    justify-content: center;
  }

  .swiper-slide > div {
    width: 80%;
  }

  .editor-swiper-pagination >>> .swiper-pagination-bullet {
    width: 37px;
    margin: 0 8px;
    border-radius: unset;

    background-color: unset;
    background-image: url('@/assets/selected-phone.webp');
  }
}
</style>
