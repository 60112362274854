<template>
  <div id="app" class="craftmods-app">
    <AppNav></AppNav>

    <AppFirstScreen></AppFirstScreen>
    <AppSecondScreen></AppSecondScreen>
    <AppThirdScreen></AppThirdScreen>
    <AppFourthScreen></AppFourthScreen>
    <AppFifthScreen></AppFifthScreen>
    <AppSixthScreen></AppSixthScreen>

    <AppBottomScreen></AppBottomScreen>

    <div class="download-app-btn" @click="downloadApp">
      <a target="_blank" class="mobile-download" :href="hrefUrl">
        <div>
          <img :src="downlaodIcon">
          <span
            :class="(0 === LANGUAGE_TYPE ? 'app-baloo2-bold-font' : 'app-sourcehan-medium-font')">
            {{ CONTENT_TEXT.GET_APP }}
          </span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import AppNav from './components/AppNav.vue'

import AppFirstScreen from './components/AppFirstScreen.vue'
import AppSecondScreen from './components/AppSecondScreen.vue'
import AppThirdScreen from './components/AppThirdScreen.vue'
import AppFourthScreen from './components/AppFourthScreen.vue'
import AppFifthScreen from './components/AppFifthScreen.vue'
import AppSixthScreen from './components/AppSixthScreen.vue'

import AppBottomScreen from './components/AppBottomScreen.vue'

import { deviceType, statisticsReq } from './utils'

import { DEVICE_TYPE_ENUM, DOWNLOAD_APPLE_PATH, DOWNLOAD_GOOGLE_PATH } from './constants'

export default {
  name: 'App',
  components: {
    AppNav,

    AppFirstScreen,
    AppSecondScreen,
    AppThirdScreen,
    AppFourthScreen,
    AppFifthScreen,
    AppSixthScreen,

    AppBottomScreen
  },

  created() {
    statisticsReq(2);
    if (deviceType() === DEVICE_TYPE_ENUM.ANDROID) this.hrefUrl = DOWNLOAD_GOOGLE_PATH;
  },

  mounted() {
    const downloadAppTtn = document.querySelector('.download-app-btn');
    if (this.IS_MOBILE) {
      downloadAppTtn.style.display = 'block';
    } else {
      downloadAppTtn.style.display = 'none';
    }
  },

  // 图片预加载
  beforeCreate() {
    let count = 0;

    // 用require的方式添加图片地址，直接添加图片地址的话，在build打包之后会查找不到图片
    // 因为打包之后的图片名称会有一个加密的字符串
    const imgs = [
      require('@/assets/craftmods-bg-pad.jpg'),
      require('@/assets/craftmods-bg-phone.jpg'),

      require('@/assets/craftmods-icon.png'),
      // require('@/assets/craftmods-name.webp'),
      require('@/assets/tiktok-icon.webp'),
      require('@/assets/youtube-icon.webp'),

      require('@/assets/download-google-icon.webp'),
      require('@/assets/download-apple-icon.webp'),

      require('@/assets/library-bg.webp'),
      require('@/assets/library-head-bg.webp'),
      require('@/assets/library-bg-phone.webp'),

      require('@/assets/icon-arrow.svg'),

      require('@/assets/comment-star.webp'),

      require('@/assets/download-icon.webp'),

      require('@/assets/select-phone.webp'),
      require('@/assets/selected-phone.webp'),

      require('@/assets/gameplay-1.webp'),
      require('@/assets/gameplay-2.webp'),
      require('@/assets/gameplay-3.webp'),
      require('@/assets/gameplay-4.webp'),
      require('@/assets/gameplay-5.webp'),
      require('@/assets/gameplay-6.webp'),
      require('@/assets/gameplay-7.webp'),

      require('@/assets/youtube-ideas.webp'),
      require('@/assets/play-ideas.webp'),

      // 评论头像
      require('@/assets/1.webp'),
      require('@/assets/2.webp'),
      require('@/assets/3.webp'),
      require('@/assets/4.webp'),
      require('@/assets/5.webp'),
      require('@/assets/6.webp'),
      require('@/assets/7.webp'),
      require('@/assets/8.webp'),
      require('@/assets/9.webp'),
      require('@/assets/10.webp'),
      require('@/assets/11.webp'),
      require('@/assets/12.webp'),

      // 视频预加载前的图片
      require('@/assets/cover/app-challenge.webp'),
      require('@/assets/cover/app-chat.webp'),
      require('@/assets/cover/app-community.webp'),

      require('@/assets/cover/library-addon.webp'),
      require('@/assets/cover/library-map.webp'),
      require('@/assets/cover/library-skin.webp'),

      require('@/assets/cover/editor-addon.webp'),
      require('@/assets/cover/editor-animation.webp'),
      require('@/assets/cover/editor-dressup.webp'),
      require('@/assets/cover/editor-skin.webp'),
      require('@/assets/cover/editor-world.webp')
    ];

    // 中文地址需要格外预加载部分图片
    if (1 === Vue.prototype.LANGUAGE_TYPE) {
      imgs.push(
        require('@/assets/kuai-icon.webp'),
        require('@/assets/redbook-icon.webp'),
        require('@/assets/bilibili-icon.webp'),

        require('@/assets/download-google-cn-icon.webp'),
        require('@/assets/download-apple-cn-icon.webp'),
        // require('@/assets/download-apk-cn-icon.webp')

        require('@/assets/gameplay-1-cn.webp'),
        require('@/assets/gameplay-2-cn.webp'),
        require('@/assets/gameplay-3-cn.webp'),
        require('@/assets/gameplay-4-cn.webp'),
        require('@/assets/gameplay-5-cn.webp'),
        require('@/assets/gameplay-6-cn.webp')
      );
    }

    for (let img of imgs) {
      let image = new Image();
      image.src = img;
      image.onload = () => {
        count++;
      };
    }

    return count;
  },

  data() {
    return {
      downlaodIcon: require('@/assets/download-icon.webp'),

      hrefUrl: DOWNLOAD_APPLE_PATH // 默认跳转苹果的下载地址
    };
  },

  methods: {
    downloadApp() {
      const isIos = deviceType() === DEVICE_TYPE_ENUM.IOS;
      statisticsReq(isIos ? 5 : 6);
    }
  }
}
</script>

<style>
.craftmods-app {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;

  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;
  background: #212121;
}

a {
  text-decoration: unset;
}

.craftmods-font-title {
  color: #FFFFFF;
  font-size: calc(1.35rem + 1.5vw);

  font-style: normal;
  text-transform: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(180deg, #70E864 0%, #34A923 100%);
}

.download-app-btn {
  display: none;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 30px;
  z-index: 999;
}

.mobile-download {
  display: block;

  width: 260px;
  height: 60px;
  min-width: 260px;
  margin: 0 auto;
  padding: 0 20px;
  line-height: 55px;

  border-radius: 10px;
  box-shadow: inset 0px -5px 0px 0px rgba(0,0,0,0.16);
  background: linear-gradient( 180deg, #70E864 0%, #34A923 100%);
}

.mobile-download > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-download img {
  width: 18px;
  height: 18px;
  margin-right: 10px
}

.mobile-download span {
  font-size: 24px;
  color: #000000;
  font-weight: 600;
}

.app-baloo2-bold-font {
  font-family: "Baloo2-Bold";
}

/* .app-baloo2-extrabold-font {
  font-family: "Baloo2-ExtraBold";
} */

.app-baloo2-medium-font {
  font-family: "Baloo2-Medium";
}

/* .app-baloo2-regular-font {
  font-family: "Baloo2-Regular";
} */

.app-baloo2-semibold-font {
  font-family: "Baloo2-SemiBold";
}

.app-saf-fix-font {
  font-family: "SAF-fix";
}


.app-douyin-font {
  font-family: "Douyin-Bold";
}

.app-sourcehan-bold-font {
  font-family: "Source-Han-Bold";
}

.app-sourcehan-medium-font {
  font-family: "Source-Han-Medium";
}

@media (max-width: 750px) {
  .craftmods-font-title {
    font-size: calc(1.55rem + 1.5vw);
  }
}

@font-face {
  font-family: "Baloo2-Bold";
  src: url('@/assets/Baloo2-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "Baloo2-ExtraBold";
  src: url('@/assets/Baloo2-ExtraBold.ttf');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Baloo2-Medium";
  src: url('@/assets/Baloo2-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "Baloo2-Regular";
  src: url('@/assets/Baloo2-Regular.ttf');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Baloo2-SemiBold";
  src: url('@/assets/Baloo2-SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SAF-fix";
  src: url('@/assets/SAF-fix.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Douyin-Bold";
  src: url('@/assets/DouyinSansBold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Source-Han-Bold";
  src: url('@/assets/SourceHanSansCN-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Source-Han-Medium";
  src: url('@/assets/SourceHanSansCN-Medium.otf');
  font-weight: normal;
  font-style: normal;
}
</style>
