import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false;

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { isMobile, getLanguageTypeByHerf } from './utils';
import { CONTENT_TEXT_CN, CONTENT_TEXT_EN } from './constants';

Vue.use(VueAwesomeSwiper);

Vue.prototype.IS_MOBILE = isMobile();

const languageType = getLanguageTypeByHerf();
Vue.prototype.LANGUAGE_TYPE = languageType;

Vue.prototype.CONTENT_TEXT = 0 === languageType ? CONTENT_TEXT_EN : CONTENT_TEXT_CN

new Vue({
  render: h => h(App),
}).$mount('#app')
