<template>
  <div class="sixth-screen">
    <div class="content-box">
      <div
        :class="
          'content-box-title ' +
          'craftmods-font-title ' + 
          (0 === LANGUAGE_TYPE ? 'app-saf-fix-font' : 'app-douyin-font')">
        <span>{{ CONTENT_TEXT.SIXTH_TITLE_PC }}</span>
      </div>

      <div
        :class="
          'content-box-title-phone ' +
          'craftmods-font-title ' +
          (0 === LANGUAGE_TYPE ? 'app-saf-fix-font' : 'app-douyin-font')">
        <span>{{ CONTENT_TEXT.SIXTH_TITLE }}</span>
      </div>

      <div class="content-box-comments">
        <swiper :options="swiperOption">
          <swiper-slide>
            <div
              class="comment-box app-baloo2-medium-font"
              v-for="(item, index) of commentsFirstScreen"
              :key="index">
              <div><img :src="commentStar" class="comment-star"></div>
              <div class="comment-content"><span>{{ item.content }}</span></div>
              <div class="comment-user">
                <img :src="item.thumb">
                <span class="app-baloo2-bold-font">{{ item.name }}</span>
              </div>
            </div>
          </swiper-slide>

          <swiper-slide>
            <div
              class="comment-box app-baloo2-medium-font"
              v-for="(item, index) of commentsSecondScreen"
              :key="index">
              <div><img :src="commentStar" class="comment-star"></div>
              <div class="comment-content"><span>{{ item.content }}</span></div>
              <div class="comment-user">
                <img :src="item.thumb">
                <span class="app-baloo2-bold-font">{{ item.name }}</span>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="content-box-comments-phone">
        <swiper :options="swiperOptionPhone">
          <swiper-slide
            v-for="(item, index) of [...commentsFirstScreen, ...commentsSecondScreen]"
            :key="index">
            <div class="comment-box app-baloo2-medium-font">
              <div><img :src="commentStar" class="comment-star"></div>
              <div class="comment-content"><span>{{ item.content }}</span></div>
              <div class="comment-user">
                <img :src="item.thumb">
                <span class="app-baloo2-bold-font">{{ item.name }}</span>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SixthScreen',

  data() {
    return {
      commentStar: require('@/assets/comment-star.webp'),

      commentsFirstScreen: [
        // 第一屏
        {
          content: `almost all the minecraft PE mod apps require you to pay or the mods dont work
            and are hard to download. But this one if different. you dont have to pay to get mods
            (daily reward and watching adds) and the mods and skins work very well. to be honest,
            the best`,
          thumb: require('@/assets/1.webp'),
          name: 'Driver-man'
        },
        {
          content: `It lets you download and make amazing mods all for free! I haven’t found any
            crashes or bugs, other than a mod (like crackers witherstorm mod) being too much data
            for someone’s device. -Katsupp1012`,
          thumb: require('@/assets/2.webp'),
          name: 'kdiwoxjsoxosi'
        },
        {
          content: `I like this game so much this is the best game ever idc what you say this is
            better then other games that has Minecraft mods this is the best of them all can you
            give me 1000 gems 💎 I’m that guy that send a comment on you newest video`,
          thumb: require('@/assets/3.webp'),
          name: 'Aīe'
        },
        {
          content: `So I love how you can create new addons yourself but I think you guys did a
            great job but there are way to many adds so if you are reading this pls fix the add
            problem I would really appreciate it🙂`,
          thumb: require('@/assets/4.webp'),
          name: 'gingersis425'
        },
        {
          content: `This app was one of the best I ever downloaded. Ever since I got it, I've been
            releasing mods for my followers and enjoying the feeling of playing with something you
            just made. 100% the best app ever.`,
          thumb: require('@/assets/5.webp'),
          name: 'VillagerAristocrat'
        },
        {
          content: `This is soooo perfect for me. I cost no money unless you pay for extra but that
            isn’t required for this unlike the other. So I love love love this a I really recommend
            it. P.S all of it works in Minecraft`,
          thumb: require('@/assets/6.webp'),
          name: 'may 34567i90'
        }
      ],
      commentsSecondScreen: [
        {
          content: `It’s a amazing app when I made a report on the price of the  they got right on
            it I love this app 😄 now I can download the mods I want for cheap thanks the
            developers of craft mod your amazing 🤩`,
          thumb: require('@/assets/7.webp'),
          name: 'GGVarnado'
        },
        {
          content: `I use this app all the time and I love being able to create things and share it
            with other people! 14/10`,
          thumb: require('@/assets/8.webp'),
          name: 'Kitty lover242'
        },
        {
          content: `This is the best game in the world. I don’t care about the other reviews just
            download it I don’t care.`,
          thumb: require('@/assets/9.webp'),
          name: 'Geomet has the'
        },
        {
          content: `The reason why I gave this a 5 star is because I love it a lot it’s one of the
            best apps I have ever had.`,
          thumb: require('@/assets/10.webp'),
          name: 'mastermime2021'
        },
        {
          content: `This is an amazing app! You came make 3d blocks, mobs, food, items, etc. they
            update the game weekly. Totally recommend!`,
          thumb: require('@/assets/11.webp'),
          name: 'Art2Code'
        },
        {
          content: `they actually have a lot of the mods I wanted (except the YEET STICK, security,
            or security cameras,) but it’s ok! I recommend this`,
          thumb: require('@/assets/12.webp'),
          name: '64@heheydyddy'
        }
      ],

      swiperOption: {
        effect: 'fade',
        loop: true,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false
        }
      },

      swiperOptionPhone: {
        slidesPerView: 2,
        spaceBetween: 20,
        speed: 2500,
        // loop: true,
        centeredSlides: true,
        watchSlidesProgress : true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      }
    };
  }
}
</script>

<style scoped>
.sixth-screen {
  display: flex;
  justify-content: center;
  
  width: 100%;
  padding: 86px 0 118px 0;
}

.content-box {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 95%;
}

.content-box-title {
  display: block;
}

.content-box-title-phone {
  display: none;
}

.content-box-comments {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-top: 120px;
  width: 100%;
  max-width: 1300px;
}

.content-box-comments-phone {
  display: none;

  margin-top: 35px;
  width: 98%;
  /* max-width: 1300px; */
}

.comment-box {
  width: 24%;
  height: 290px;
  margin: 15px;
  padding: 24px 36px;

  border-radius: 16px;
  box-shadow: inset 0px -5px 0px 0px #22611A;
  background: linear-gradient( 180deg, #696969 0%, #2B2B2B 100%);

  font-size: 18px;
  color: #FFFFFF;
}

.comment-star {
  height: 28px;
}

.comment-content {
  overflow-y: scroll;
  height: 175px;
}
.comment-content::-webkit-scrollbar {
  display: none;
}

.comment-user {
  display: flex;
  align-items: center;

  margin-top: 20px;
}

.comment-user img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin-right: 18px;
}

.comment-user span {
  font-size: 20px;
  color: #FFFFFF;
}

.swiper-slide {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 750px) {
  .sixth-screen {
    padding: 40px 0;
  }

  .content-box-title {
    display: none;
  }

  .content-box-title-phone {
    display: block;
  }

  .content-box-comments {
    display: none;
  }

  .content-box-comments-phone {
    display: flex;
    min-width: 600px;
  }

  .comment-box {
    width: 306px;
    min-width: 150px;
    margin: 0 auto;
  }
}
</style>
