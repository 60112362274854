<template>
  <div class="first-screen">
    <div class="content-box">
      <div
        :class="'content-box-title ' + (0 === LANGUAGE_TYPE ? 'app-saf-fix-font' : 'app-douyin-font')">
        <span>
          {{ CONTENT_TEXT.FIRST_TITLE_1 }}<br>
          {{ CONTENT_TEXT.FIRST_TITLE_2 }}<br>
          {{ CONTENT_TEXT.FIRST_TITLE_3 }}</span>
      </div>
      <div
        :class="
          'content-box-desc ' +
          (0 === LANGUAGE_TYPE ? 'app-baloo2-bold-font' : 'app-sourcehan-medium-font')">
        <div><span class="desc-dots"></span>{{ CONTENT_TEXT.FIRST_DESC_1 }}</div>
        <div><span class="desc-dots"></span>{{ CONTENT_TEXT.FIRST_DESC_2 }}</div>
      </div>
      <div class="content-box-download" v-if="0 === LANGUAGE_TYPE">
        <a :href="applePath" target="_blank" @click="toApple"><img :src="appleIcon"></a>
        <a :href="googlePath" target="_blank" @click="toGoole"><img :src="googleIcon"></a>
      </div>

      <!-- content-box-download-cn -->
      <div class="content-box-download" v-else>
        <a :href="applePath" target="_blank" @click="toApple"><img :src="appleCnIcon"></a>
        <a :href="googlePath" target="_blank" @click="toGoole"><img :src="googleCnIcon"></a>
        <!-- <a :href="apkPath" target="_blank"><img :src="apkIcon"></a> -->
      </div>
    </div>
  </div>
</template>

<script>
import { DOWNLOAD_APK_PATH, DOWNLOAD_APPLE_PATH, DOWNLOAD_GOOGLE_PATH } from '@/constants/index';

import { getWindowHeight, statisticsReq } from '@/utils/index'

export default {
  name: 'FirstScreen',

  data() {
    return {
      googleIcon: require('@/assets/download-google-icon.webp'),
      appleIcon: require('@/assets/download-apple-icon.webp'),

      googleCnIcon: require('@/assets/download-google-cn-icon.webp'),
      appleCnIcon: require('@/assets/download-apple-cn-icon.webp'),
      // apkIcon: require('@/assets/download-apk-cn-icon.webp'),

      applePath: DOWNLOAD_APPLE_PATH,
      googlePath: DOWNLOAD_GOOGLE_PATH,
      apkPath: DOWNLOAD_APK_PATH
    };
  },

  mounted() {
    const screen = document.querySelector('.first-screen');
    screen.style.height = getWindowHeight() + 'px';

    const contentBoxDownload = document.querySelector('.content-box-download');
    if (this.IS_MOBILE) {
      contentBoxDownload.style.display = 'none';
    }
  },

  methods: {
    toApple() {
      statisticsReq(5);
    },

    toGoole() {
      statisticsReq(6);
    }
  }
}
</script>

<style scoped>
.first-screen {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 100%;
  min-height: 600px;
  overflow-x: hidden;
  margin-left: auto;
  overflow-y: hidden;

  background-size: cover;
  background-color: #141414;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.1s ease;
  background-image: url('@/assets/craftmods-bg-pad.jpg');
}

.content-box {
  position: absolute;
  /* bottom: 14%; */

  width: 98%;
  color: #FFFFFF;
  max-width: 1320px;
  min-width: 320px;
}

.content-box > div {
  margin: 36px 26px;
}

.content-box-title {
  font-size: calc(1.7rem + 1.5vw);
  margin-bottom: 30px;
}

.content-box-title span {
  line-height: 68px;
}

.content-box-desc {
  display: block;
  font-size: calc(0.55rem + 1.0vw);
  margin-bottom: 48px;
}

.content-box-desc div {
  position: relative;
}

.desc-dots {
  position: absolute;
  left: -1.5%;
}

.desc-dots::before {
  content: '•';
}

.content-box-download a:nth-child(1) {
  margin-right: 46px;
}
.content-box-download-cn a:nth-child(2) {
  margin: 0 20px;
}

.content-box-download img {
  width: 20%;
  max-width: 270px;
  border-radius: 10px;
  box-shadow: inset 0px -6px 0px 0px rgba(0,0,0,0.16);
}
.content-box-download-cn img {
  width: 14%;
  max-width: 270px;
  border-radius: 10px;
  box-shadow: inset 0px -6px 0px 0px rgba(0,0,0,0.16);
}

@media (max-width: 1300px) {
  .content-box-title {
    font-size: calc(1.5rem + 1.5vw);
  }

  .content-box-title span {
    line-height: 58PX;
  }

  .content-box-download img {
    width: 18%;
  }
}

@media (max-width: 750px) {
  .content-box-title span {
    line-height: 50PX;
  }

  .first-screen {
    background-image: url('@/assets/craftmods-bg-phone.jpg');
  }

  .content-box-download, .content-box-download-cn {
    display: none;
  }

  .content-box {
    bottom: 18%;

    display: flex;
    align-items: center;
    flex-direction: column;

    text-align: center;
  }

  .content-box > div {
    margin: 0 0 10px 0;
  }

  .content-box-desc div {
    display: none;
  }
}
</style>
